<template>
  <div id="app" class="container-fluid">
    <div v-if="loading" class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div v-if="errored">
      <b-alert variant="danger" show>{{ error_message }}</b-alert>
    </div>
    <!-- welcome -->
    <div id="welcome" v-if="current_step < 0">
      <div>
        <p class="h1">Teste dein Bewusstsein!</p>
        <p>Beantworte X einfache Fragen und bekomme so einen Vorgeschmack auf die 8awareness Bewusstseinsanalyse!</p>
        <b-button @click="load_survey()">Los!</b-button>
        <br>
        <img alt="8awareness logo" src="./assets/8_logo.svg">
        <img alt="brainpunks logo" src="./assets/brainpunks_logo.png">
      </div>
    </div>
    <!-- -->
    <div id="survey" v-else-if="current_step >= 0 && current_step < questions.length">
      <b-card>
        <b-card-header>
          <b-progress :max="questions.length" height="2rem">
            <b-progress-bar :value="current_step + 1">
              <strong>{{ current_step + 1 }} / {{ questions.length }}</strong>
            </b-progress-bar>
          </b-progress>
        </b-card-header>
        <b-card-body>
          <p class="h1">{{ questions[current_step].title }}</p>
          <b-button @click="next_question(-2)">Find ich richtig doof</b-button>
          <b-button @click="next_question(-1)">Find ich blöd</b-button>
          <b-button @click="next_question(0)">Neutral egal</b-button>
          <b-button @click="next_question(1)">Find ich gut</b-button>
          <b-button @click="next_question(2)">Find ich richtig geil</b-button>

        </b-card-body>
        <b-card-footer>
          <b-button v-if="current_step > 0" @click="current_step -= 1">Zurück</b-button>
        </b-card-footer>
      </b-card>
    </div>
    <div id="evaluation" v-else-if="current_step === questions.length">
      <p>Deine Persona: <strong>{{ this.persona }}</strong></p>
      <p>Ergebnis: {{ this.evaluation }}</p>
    </div>
    <div>
      <p>Datenschutz und Impressum und EULA?</p>
      <p>Support Konktakt alles ein Link?</p>
    </div>
  </div>
</template>

<script>

import {
  BAlert, BButton, BProgress, BProgressBar, BCard, BCardBody, BCardFooter, BCardHeader
} from "bootstrap-vue";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'App',
  data: () => ({
    persona: null,
    evaluation: {rot: 0, blau: 0, grun: 0, orange: 0, purpur: 0, gelb: 0, turkis: 0, koralle: 0},
    error_message: "",
    loading: false,
    errored: false,
    answer_array: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    current_step: -1,
    questions: [
      {color: "rot", title: "Ich nehme bei Projekten das Ruder in die Hand"},
      {color: "rot", title: "Projekte müssen Schlagkraft haben"},
      {color: "rot", title: "Ich liebe schnelle Entscheidungen"},
      {color: "rot", title: "Ich stelle mich jedem Konflikt"},
      {color: "blau", title: "Ich liebe Ordnung"},
      {color: "blau", title: "Regeln sind essentiell für eine gute Zusammenarbeit"},
      {color: "blau", title: "Klare Strukturen geben Sicherheit"},
      {color: "blau", title: "Regeln sind wichtig"},
      {color: "grun", title: "Ich brauche Harmonie im Team"},
      {color: "grun", title: "Ich versetze mich immer in andere hinein"},
      {color: "grun", title: "Wichtige Entscheidungen müssen im Konsens getroffen werden"},
      {color: "grun", title: "Konflikte sind unwichtig und stören nur"},
      {color: "orange", title: "Ich setze mir stets hohe Ziele"},
      {color: "orange", title: "Second place is the first looser"},
      {color: "orange", title: "Nur das Resultat zählt"},
      {color: "orange", title: "Ich will gewinnen"},
      {color: "purpur", title: "Ohne Loyalität geht es nicht"},
      {color: "purpur", title: "Never change a Running System"},
      {color: "purpur", title: "Ich bin treu bis in den Tod"},
      {color: "purpur", title: "Für mein Team stehe ich voll und ganz ein"},
      {color: "gelb", title: "Freiheit ist das einzige, was zählt"},
      {color: "gelb", title: "Innovation ist essentiell fürs Überleben"},
      {color: "gelb", title: "Ich will recht haben"},
      {color: "gelb", title: "Anpassung zerstört Fortschritt"},
      {color: "turkis", title: "Ich bin ein Tagträumer"},
      {color: "turkis", title: "Alles ist miteinander verbunden"},
      {color: "turkis", title: "Details strengen mich wahnsinnig an"},
      {color: "turkis", title: "Die Welt ist einfach"},
      {color: "koralle", title: "Es geht darum, durchs eigene Sein zu wirken"},
      {color: "koralle", title: "Spiritualität ist für mich natürlich"},
      {color: "koralle", title: "Wir sind Gott"},
      {color: "koralle", title: "Jeder hat seine eigene Wahrnehmung der Welt"},
    ],
  }),
  components: {
    BAlert,
    BButton,
    BProgress,
    BProgressBar,
    BCardFooter,
    BCardHeader,
    BCardBody,
    BCard,
  },
  methods: {
    next_question: function (points) {
      this.answer_array[this.current_step] = points
      this.current_step += 1
      if (this.current_step == this.questions.length) {
        this.answer_array.forEach((value, i) => {
          this.evaluation[this.questions[i].color] += value
          const list = this.evaluation
          const sorted_keys = Object.keys(list)
              .sort(function (a, b) {return list[b] - list[a]})
          console.log(sorted_keys)
          this.persona = sorted_keys[0] + '_' + sorted_keys[1]
        });
      }

    },
    load_survey: function () {
      this.current_step += 1
    },
  }
}
</script>

<style lang="scss">
@import "./../node_modules/bootstrap/dist/css/bootstrap.css";
@import "./../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
</style>
